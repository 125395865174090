@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;1,100&display=swap');

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  background: #fbeec1;
}
img {
  max-width: 100%;
}

header.hero {
  background: #51a2da;
  margin-top: 0px;
  padding: 0px;
  height: 100vh;
  background: url('/public/drywall.jpg');
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
}
header {
  background: #659dbd;
  border-bottom: 10px solid #8ee4af;
}
.form {
  margin-bottom: 20px;
}
.mainContainer {
  margin-top: 20px;
}
.mainHeading {
  margin-bottom: 30px;
}
.logoImg {
  height: 180px;
  max-width: 100%;
}
.logoImg img {
  cursor: pointer;
}

.headerElements {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: space-between;
}

h2 {
  margin: 0px;
  padding: 0px;
}
nav {
  float: right;
  margin: 0px;
}
nav ul {
  list-style: none;
  margin: 0px;
}
nav ul li {
  float: left;
  margin-right: 17px;
}
nav ul li a {
  font-size: 1.3rem;
  text-decoration: none;
  color: #05386b;
  font-weight: bold;
  text-transform: uppercase;
}
nav ul li a:hover {
  text-decoration: underline;
}
.contact {
  display: flex;
  justify-content: space-around;
}
.services {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.servicesHeading {
  margin-top: 30px;
}
.serviceContainer {
  background: #7395ae;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.serviceContainer ul {
  padding-left: 30px;
}
.serviceContainer h4 {
  font-size: 1.3rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.services h3 {
  font-size: 2.5rem;
  margin-bottom: 5px;
  color: #fff;
}
.services img {
  float: left;
  height: 150px;
  margin: 10px 35px 0px 10px;
  border-radius: 5px;
}
.person {
  
  font-weight: bold;
  margin-bottom:5px;
  font-size: 1.4rem;
  margin-right:5px;

}

a span.phone {
  font-size: 2.1rem;
  color: #000;
  text-decoration: none;
}
.fullWidthHome {
  height: 150px;
  text-align: center;
  padding: 20px;
  background: url('/public/finished.jpg');
  background-position: center;
  background-size: cover;
}
.fullWidthHome h3 {
  font-size: 2.4rem;
  color: #fff;
  background: #3a3b3c;
  opacity: 0.8;
  border-radius: 5px;
}
h1 {
  text-align: center;
}
.snail {
  text-align: center;
}
.snail img {
  max-width: 50%;
  margin: 0 auto;
  display: block;
  margin-top: 50px;
  border-radius: 5px;
}
h5 {
  font-size: 1.9rem;
  color: #fff;
}
h5 a {
  color: #fff;
  text-decoration: none;
}
h5 a:hover {
  text-decoration: underline;
}
h5 a:visited {
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .services {
    display: block;
  }
  .serviceContainer {
    margin-right: 0px;
  }
  .contact {
    display: block;
  }
  .fullWidthHome h3 {
    font-size: 1.2rem;
  }
  nav {
    display: none;
  }
  .phone {
    display: block;
  }
}

